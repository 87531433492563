import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  isLogin: false,
  user: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    saveLoginUserData(state, action) {
      state.user = action.payload.user;
      state.isLogin = true;
      state.accessToken = action.payload.token;
      state.error = null;
    },
    logOutRequest(state) {
      return initialState;
    },
    resetAuthState(state) {
      return initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLogin = false;
      state.accessToken = "";
    },
  },
});

export const {
  saveLoginUserData,
  logOutRequest,
  resetAuthState,
  setLoading,
  setError,
} = authSlice.actions;

export default authSlice.reducer;
