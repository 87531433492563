export const apiUrl =
  process.env.REACT_APP_API_URL || "https://api.radlogs.com";
export const imageUrl = (url) => `${apiUrl}/api${url}`;

/**
 *
 * @param {String} link
 * @returns {String}
 */
export const BaseURL = (link) => `${apiUrl}/api/${link}`;

export const apiHeader = (token, isFormData, additionalOptions = {}) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  } else if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      ...additionalOptions,
    };
  } else if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  } else if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};
