import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import classes from "./UserLayout.module.css";
import { ReactComponent as RadlogsLogo } from "../../assets/icons/radlogs-logo.svg";
import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg";
import SearchBar from "../../components/UI/SearchBar";
import CircledButton from "../../components/UI/CircledButton";
import Sidebar from "../../components/UI/Sidebar";
import clsx from "clsx";
const UserLayout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={classes.layoutWrapper}>
      <Navbar className={classes.navbar}>
        <div className="d-flex g-0 w-100">
          <div className="d-flex align-items-center">
            <Navbar.Brand className={classes.brand}>
              <RadlogsLogo className={classes.logo} />
              <span className={classes.logoText}>Radlogs</span>
            </Navbar.Brand>
          </div>
          <div className="d-flex align-items-center justify-content-start">
            <CircledButton
              icon={ArrowLeftIcon}
              ariaLabel="Toggle Sidebar"
              onClick={toggleSidebar}
              className={clsx(classes.sidebarToggle, {
                [classes.collapsed]: isSidebarCollapsed,
              })}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end ms-auto">
            <SearchBar />
            <div className="ms-4">
              <CircledButton icon={BellIcon} ariaLabel="Notifications" />
            </div>
          </div>
        </div>
      </Navbar>
      <div className={classes.contentWrapper}>
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          toggleSidebar={toggleSidebar}
          user={user}
        />
        <main className={classes.mainContent}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default UserLayout;
