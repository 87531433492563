import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "./components/UI/Loader";
import ProtectedRoute from "./routesProtected";
import AuthLayout from "./layouts/AuthLayout";
import UserLayout from "./layouts/UserLayout";

// Lazy load only pages, not layouts
const SignupPage = lazy(() => import("../src/pages/Signup"));
const LoginPage = lazy(() => import("../src/pages/Login"));
const DashboardPage = lazy(() => import("../src/pages/Dashboard"));
const SettingsPage = lazy(() => import("../src/pages/Settings"));
const CompletedCasesPage = lazy(() => import("../src/pages/CompletedCases"));
const CaseEntry = lazy(() => import("../src/pages/CaseEntry"));
const LogoutPage = lazy(() => import("../src/pages/Logout"));
const ForgotPasswordPage = lazy(() => import("../src/pages/ForgotPassword"));
const ResetPasswordPage = lazy(() => import("../src/pages/ResetPassword"));

export default function AppRoutes() {
  const isAuthenticated = useSelector((state) => state.auth.isLogin);

  const routes = useRoutes([
    {
      path: "",
      element: <AuthLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" />, index: true },
        {
          path: "signup",
          element: (
            <Suspense fallback={<Loader />}>
              <SignupPage />
            </Suspense>
          ),
        },
        {
          path: "login",
          element: (
            <Suspense fallback={<Loader />}>
              <LoginPage />
            </Suspense>
          ),
        },
        {
          path: "logout",
          element: (
            <Suspense fallback={<Loader />}>
              <LogoutPage />
            </Suspense>
          ),
        },
        {
          path: "forgot-password",
          element: (
            <Suspense fallback={<Loader />}>
              <ForgotPasswordPage />
            </Suspense>
          ),
        },
        {
          path: "reset-password",
          element: (
            <Suspense fallback={<Loader />}>
              <ResetPasswordPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "",
      element: <ProtectedRoute />,
      children: [
        {
          path: "",
          element: <UserLayout />,
          children: [
            {
              path: "dashboard",
              element: (
                <Suspense fallback={<Loader />}>
                  <DashboardPage />
                </Suspense>
              ),
            },
            {
              path: "settings",
              element: (
                <Suspense fallback={<Loader />}>
                  <SettingsPage />
                </Suspense>
              ),
            },
            {
              path: "completed-cases",
              element: (
                <Suspense fallback={<Loader />}>
                  <CompletedCasesPage />
                </Suspense>
              ),
            },
            {
              path: "case-entry",
              element: (
                <Suspense fallback={<Loader />}>
                  <CaseEntry />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/login" />,
    },
  ]);

  return routes;
}
