import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  role: '',
  states: []
};
const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  //   reducer needs a map
  reducers: {
    setUserRole(state, action) {
      state.role = action.payload;
    },

    setStates(state, action) {
      state.states = action.payload;
    }
  }
});
export const { setUserRole, setStates } = commonSlice.actions;
export default commonSlice.reducer;
