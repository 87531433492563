import React from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import classes from "./SearchBar.module.css";

const SearchBar = ({
  variant = "primary",
  placeholder = "Search here...",
  value,
  onChange,
}) => {
  return (
    <InputGroup className={classes.searchBar + " " + classes[variant]}>
      <Button
        variant="outline-secondary"
        id="button-search"
        className={classes.searchButton}
      >
        <SearchIcon className={classes.searchIcon} />
      </Button>
      <Form.Control
        type="search"
        placeholder={placeholder}
        aria-label="Search"
        aria-describedby="button-search"
        className={classes.searchInput}
        value={value}
        onChange={onChange}
      />
    </InputGroup>
  );
};

export default SearchBar;
