import { Get } from "./axiosFunctions";
import { BaseURL } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setError, logOutRequest } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.auth);

  const verifyToken = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const response = await Get(BaseURL("users/verify-token"), accessToken);

      if (response?.data?.success === false) {
        throw new Error(response?.data?.message || "Token verification failed");
      }

      return response.data;
    } catch (err) {
      dispatch(setError(err?.response?.data?.message || err.message));
      dispatch(logOutRequest());
      navigate("/login");
      throw err;
    } finally {
      dispatch(setLoading(false));
    }
  }, [accessToken, dispatch, navigate]);

  return {
    verifyToken,
  };
};

export default useAuth;
