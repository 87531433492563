import React from "react";
import { Button } from "react-bootstrap";
import classes from "./CircledButton.module.css";
import clsx from "clsx";
const CircledButton = ({
  icon: Icon,
  onClick,
  ariaLabel,
  className,
  variant = "primary",
}) => {
  return (
    <Button
      className={clsx(classes.circledButton, className, classes[variant])}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <Icon className={classes.icon} />
    </Button>
  );
};

export default CircledButton;
