import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuth from "./api/useAuth";
import Loader from "./components/UI/Loader";

const ProtectedRoute = () => {
  const [isVerifying, setIsVerifying] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isLogin);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { verifyToken } = useAuth();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;

    const verifyAccess = async () => {
      if (isAuthenticated && accessToken) {
        try {
          await verifyToken();
          if (mounted) {
            setIsValid(true);
          }
        } catch (error) {
          if (mounted) {
            setIsValid(false);
          }
        }
      } else {
        if (mounted) {
          setIsValid(false);
        }
      }
      if (mounted) {
        setIsVerifying(false);
      }
    };

    verifyAccess();

    return () => {
      mounted = false;
    };
  }, [isAuthenticated, accessToken, verifyToken]);

  if (isVerifying) {
    return <Loader />;
  }

  if (!isAuthenticated || !isValid || !accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
