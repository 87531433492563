import axios from "axios";
import { toast } from "react-toastify";

/**
 * @description Sends a Get request to api
 * @param {String} route
 * @example "/api/route"
 * @returns Promise<any>
 */

const Get = async (route, accessToken, showAlert = true) => {
  console.log("🔍 GET Request:", { route, accessToken });
  const options = accessToken
    ? {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    : {
        headers: {
          Accept: "application/json",
        },
      };
  try {
    const response = await axios.get(route, options);
    console.log("✅ GET Success:", response.data);
    return response;
  } catch (error) {
    console.error("❌ GET Error:", {
      message: error.message,
      response: error?.response?.data,
      status: error?.response?.status,
    });
    return error.response;
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 * @returns Promise<any>
 */

const Post = async (route, data, headers) => {
  console.log("📮 POST Request:", { route, data, headers });
  try {
    const response = await axios.post(route, data, headers);
    console.log("✅ POST Success:", response.data);
    return response;
  } catch (error) {
    console.log("Error: ", error);
    console.error("❌ POST Error:", {
      message: error.message,
      response: error?.response?.data,
      status: error?.response?.status,
    });
    return error.response;
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

const Patch = async (route, data, headers) => {
  console.log("🔧 PATCH Request:", { route, data, headers });
  try {
    const response = await axios.patch(route, data, headers);
    console.log("✅ PATCH Success:", response.data);
    return response;
  } catch (error) {
    console.log("Error: ", error);
    console.error("❌ PATCH Error:", {
      message: error.message,
      response: error?.response?.data,
      status: error?.response?.status,
    });
    return error.response;
  }
};

/**
 * @description Sends a post request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

const Put = async (route, data, headers) => {
  console.log("📝 PUT Request:", { route, data, headers });
  try {
    const response = await axios.put(route, data, headers);
    console.log("✅ PUT Success:", response.data);
    return response;
  } catch (error) {
    console.log("Error: ", error);
    console.error("❌ PUT Error:", {
      message: error.message,
      response: error?.response?.data,
      status: error?.response?.status,
    });
    return error.response;
  }
};

/**
 * @description Sends a Delete request to api
 * @param {String} route
 * @example "/api/route"
 * @param {Object} data
 * @example {foo:bar}
 *   @returns Promise<any>
 */

const Delete = async (route, data, headers) => {
  try {
    return data == null
      ? await axios.delete(route, headers)
      : await axios.delete(route, data, headers);
  } catch (error) {
    console.log("Error: ", error);
    console.error("❌ DELETE Error:", {
      message: error.message,
      response: error?.response?.data,
      status: error?.response?.status,
    });
    return error.response;
  }
};

export { Post, Put, Get, Patch, Delete };
