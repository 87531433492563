import { combineReducers } from "redux";
import authReducer from "./authSlice";
import commonReducer from "./commonSlice";
import caseReducer from "./caseSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  case: caseReducer,
});

export default rootReducer;
