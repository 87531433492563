import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import "react-modern-drawer/dist/index.css";
import "react-phone-input-2/lib/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "./assets/styles/styles.css";
import "./index.css";
import AppRoutes from "./routes";
import { PrimeReactProvider } from "primereact/api";
import { ToastProvider } from "./contexts/ToastContext";

function App() {
  const value = {
    ripple: true,
    theme: {
      "--primary-color": "red",
    },
  };
  return (
    <PrimeReactProvider value={value}>
      <ToastProvider>
        <div className="App">
          <Router>
            <AppRoutes />
          </Router>
        </div>
      </ToastProvider>
    </PrimeReactProvider>
  );
}

export default App;
