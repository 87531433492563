import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cases: [],
  totalCount: 0,
  currentCase: null,
  loading: false,
  error: null,
  stats: {
    totalCases: { count: 0, percentageChange: 0 },
    pendingCases: { count: 0, percentageChange: 0 },
    upcomingCases: { count: 0, percentageChange: 0 },
    weeklyCases: { total: 0, pending: 0, upcoming: 0 },
  },
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCases: (state, action) => {
      state.cases = action.payload.cases;
      state.totalCount = action.payload.totalCount;
    },
    setCurrentCase: (state, action) => {
      state.currentCase = action.payload;
    },
    addCase: (state, action) => {
      state.cases = [action.payload, ...state.cases];
      state.totalCount += 1;
    },
    updateCaseData: (state, action) => {
      const index = state.cases.findIndex(
        (c) => c.caseId === action.payload.caseId
      );
      if (index !== -1) {
        state.cases[index] = action.payload;
      }
      if (state.currentCase?.caseId === action.payload.caseId) {
        state.currentCase = action.payload;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetCaseState: (state) => {
      return initialState;
    },
    deleteCaseFromList: (state, action) => {
      state.cases = state.cases.filter(
        (caseItem) => caseItem.caseId !== action.payload
      );
      state.totalCount -= 1;
      if (state.currentCase?.caseId === action.payload) {
        state.currentCase = null;
      }
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
  },
});

export const {
  setCases,
  setCurrentCase,
  addCase,
  updateCaseData,
  setLoading,
  setError,
  resetCaseState,
  deleteCaseFromList,
  setStats,
} = caseSlice.actions;

export default caseSlice.reducer;
