import React from "react";
import classes from "./AuthLayout.module.css";
import { Outlet } from "react-router-dom";
const AuthLayout = () => {
  return (
    <div className="AuthLayout">
      <Outlet />
    </div>
  );
};

export default AuthLayout;
